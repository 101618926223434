import React from 'react'
import { InitialSearch } from '../scenes'

// This component renders the Year Make Model selectors to make a fitment
// search wherever you put the <div id="sd-fitment-fixed-initial-search"></div>
export const FixedInitialSearch = () => {
  return (
    <InitialSearch />
  )
}
