import React, { useState } from 'react'
import styled from 'styled-components'
import { shouldPreventRedirect } from '../utils'
import { ResetGarageButton } from './ResetGarageButton'
import { FitSelect, MyGarageButton, GoButton } from '../components'
import { useShopData, useYmm, useMyGarage, useCustomNavigation } from '../hooks'

const uniqueValues = (array) => [...new Set(array?.map(i => i?._id))]

export const SearchWithGoButton = () => {
  const origin = window.location.origin
  const shopData = useShopData()
  const { suredoneID, storefront } = shopData || {}
  const { lastYMMSearch, addMyGarageValue } = useMyGarage()
  const [selected, setSelected] = useState({})
  const [goEnabled, setGoEnabled] = useState(false)
  const customNavigate = useCustomNavigation({ useWindowToNavigate: true })

  const { dataYear, dataMake, dataModel, errorYear, errorMake, errorModel } = useYmm(suredoneID, storefront, origin, selected?.year?.value, selected?.make?.value)

  const getOptions = key => {
    if (key === 'year') {
      const years = uniqueValues(dataYear)
      return years?.sort((a, b) => b - a)
    }
    if (key === 'make') {
      const makes = uniqueValues(dataMake)
      return makes?.sort()
    }
    if (key === 'model') {
      const models = uniqueValues(dataModel)
      return models?.sort()
    }
  }

  const handleChange = e => {
    const { type } = e || {}
    setSelected(prev => ({ ...prev, [type]: e }))
    if (type === 'year') {
      setSelected(prev => ({ ...prev, make: '', model: '' }))
    }
    if (type === 'make') {
      setSelected(prev => ({ ...prev, model: '' }))
    }
    if (type === 'model') {
      setGoEnabled(true)
    }
  }

  const handleGoClick = () => {
    const { year, make, model } = selected || {}
    const { origin, pathname } = window?.location
    addMyGarageValue(selected)
    if (shouldPreventRedirect()) {
      window.location = origin + pathname
    } else {
      customNavigate(storefront, year, make, model)
    }
  }

  if (errorYear || errorMake || errorModel) return <p>Error while fetching data. If you are seeing this error within your store editor, please check the live version.</p>

  return (
    <Section id='initial-search' className='with-go-button'>
      <WrapperDropdownColumn>
        <FitSelect
          autoFocus
          id='year'
          label='Year'
          loading={!dataYear}
          disabled={!dataYear}
          value={selected.year}
          onChange={handleChange}
          options={getOptions('year')}
          placeholder={lastYMMSearch?.year?.value || 'Year'}
        />
        <FitSelect
          id='make'
          label='Make'
          value={selected.make}
          onChange={handleChange}
          options={getOptions('make')}
          loading={!dataMake && selected?.year}
          disabled={!dataMake || !selected?.year}
          placeholder={selected?.year ? 'Make' : (lastYMMSearch?.make?.value || 'Make')}
        />
        <FitSelect
          id='model'
          label='Model'
          value={selected.model}
          onChange={handleChange}
          options={getOptions('model')}
          loading={!dataModel && selected?.make}
          disabled={!dataModel || !selected?.make}
          placeholder={selected?.year ? 'Model' : (lastYMMSearch?.model?.value || 'Model')}
        />
      </WrapperDropdownColumn>
      <WrapperButtonsColumn className='go-button-wrapper'>
        <GoButton onClick={handleGoClick} disabled={!goEnabled} />
        <MyGarageButton showAsLink useWindowToNavigate />
        <ResetGarageButton />
      </WrapperButtonsColumn>
    </Section>
  )
}

const Section = styled.section`
  gap: 2%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: center;
  
  &#initial-search {
    input:focus {
      outline: none !important;
    }
  }
`
const WrapperDropdownColumn = styled.div`
  width: 79%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    padding: 4px 0;
    flex-wrap: wrap;
  }
`

const WrapperButtonsColumn = styled.div`
  gap: 5px;
  width: 19%;
  display: flex;
  align-items: end;
  justify-content: space-around;

  .drop-content {
    right: 0;
    min-width: 250px;
  }

  @media (max-width: 768px) {
    gap: 5px;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
  }
`
