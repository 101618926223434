import { useEffect } from 'react'
import { useCustomYMMSearch } from '../hooks'

export const EngagedVehicle = () => {
  const { year, make, model } = useCustomYMMSearch()

  useEffect(() => {
    const pageTitle = document.querySelector('h1')
    if (pageTitle) {
      pageTitle.innerHTML = `Results for ${year} ${make} ${model}`
    }
  }, [])

  return null
}
