import React from 'react'
import styled from 'styled-components'
import { useProduct } from '../hooks/useProduct'

export const ProductCard = ({ item }) => {
  const { data } = useProduct(item) || {}
  const { image, price, url, title } = data || {}
  const { alt, src } = image || {}

  if (!title) return null

  return (
    <Card className='product'>
      <Link href={url}>
        <Img src={src} alt={alt} loading='lazy' />
        <Span className='product__name'>{title}</Span>
        <Strong className='product__price'>${price}</Strong>
      </Link>
    </Card>
  )
}

const Card = styled.article`
  width: calc((100% - 45px) / 4);

  @media (max-width: 768px) {
    width: calc((100% - 15px) / 2)
  }

  @media (max-width: 576px) {
    width: 100%
  }
`

const Link = styled.a`
  color: unset;
  display: block;
  text-decoration: none;
`

const Img = styled.img`
  width: 100%;
  height: 175px;
  object-fit: cover;
`

const Span = styled.span`
  width: 100%;
  display: block;

  &.brand {
    font-size: 80%;
  }
`

const Strong = styled.strong`
  font-size: 120%;
`
