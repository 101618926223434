import React from 'react'
import styled from 'styled-components'
import { showOriginalViewQParam } from '../utils'

const NoResult = ({ year, make, model, currentCategoryName }) => {
  return (
    <p className='link-to-native-category'>No Results found for {year} {make} {model} and given filters. Click <a href={`?${showOriginalViewQParam}=true`}>here</a> to see all {currentCategoryName}</p>
  )
}

export const CategoryYMMLabel = ({ anyResult, year, make, model }) => {
  const currentCategoryName = window?.sdFitmentData?.categoryName
  if (!anyResult) return (<NoResult year={year} make={make} model={model} currentCategoryName={currentCategoryName} />)
  if (!currentCategoryName) return null
  if (!year || !make || !model) return null

  return (
    <Wrapper className='category-label-wrapper'>
      <H1>
        Showing results for {currentCategoryName} that fit {year} {make} {model}
      </H1>
      <p className='link-to-native-category'>Click <a href={`?${showOriginalViewQParam}=true`}>here</a> to see all {currentCategoryName}</p>
    </Wrapper>
  )
}

const H1 = styled.h1`
  margin-top: 0;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
