import React from 'react'
import { Spinner } from './Spinner'
import styled from 'styled-components'
import { useShopData } from '../hooks'
import { useQuery } from '@apollo/client'
import { GET_PRODUCT_YMM_BY_SKU } from '../queries/Fitment'

const YMMChartTable = ({ ymmArray }) => {
  if (!ymmArray || ymmArray.length === 0) return null

  const compressedYMM = ymmArray.reduce((acum, currentYMM) => {
    if (acum[currentYMM.make]) {
      if (acum[currentYMM.make][currentYMM.model]) {
        acum[currentYMM.make][currentYMM.model] = {
          min: (acum[currentYMM.make][currentYMM.model].min || 9999) > currentYMM.year ? currentYMM.year : acum[currentYMM.make][currentYMM.model].min,
          max: (acum[currentYMM.make][currentYMM.model].max || 0) < currentYMM.year ? currentYMM.year : acum[currentYMM.make][currentYMM.model].max
        }
      } else {
        acum[currentYMM.make][currentYMM.model] = {
          min: currentYMM.year,
          max: currentYMM.year
        }
      }
    } else {
      acum[currentYMM.make] = {
        [currentYMM.model]: {
          min: currentYMM.year,
          max: currentYMM.year
        }
      }
    }
    return acum
  }, {})

  const arrayOfCompressedObjects = (Object.keys(compressedYMM) || []).map(make => {
    return Object.keys(compressedYMM[make]).map(model => {
      return {
        minYear: compressedYMM[make][model].min,
        maxYear: compressedYMM[make][model].max,
        make,
        model
      }
    })
  }).flat()

  return (
    <>
      <p className='fitment-header'>This part fits the following vehicles:</p>
      <Table className='fitment-table'>
        <thead>
          <tr>
            <th>Year</th>
            <th>Make</th>
            <th>Model</th>
          </tr>
        </thead>
        <tbody>
          {(arrayOfCompressedObjects || []).map(ymm => {
            const minYear = ymm.minYear
            const maxYear = ymm.maxYear
            const make = ymm.make
            const model = ymm.model
            return (
              <tr key={`${minYear}${maxYear}${make}${model}`}>
                <td>{minYear === maxYear ? maxYear : `${minYear} - ${maxYear}`}</td>
                <td>{make}</td>
                <td>{model}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export const FitsTable = () => {
  const shopData = useShopData()
  const { suredoneID, storefront } = shopData || {}
  const sku = window?.sdFitmentData?.sku
  const { data, loading, error } = useQuery(GET_PRODUCT_YMM_BY_SKU, {
    variables: {
      sku,
      storefront,
      userId: Number(suredoneID),
      domain: window?.location?.origin
    }
  })
  const { getProductYMMBySku } = data || {}
  const { ymmci } = getProductYMMBySku || {}

  if (!sku || error) return null

  return (
    loading
      ? <Spinner />
      : <YMMChartTable ymmArray={ymmci} />
  )
}

const Table = styled.table`
  border: none;
  border-collapse: collapse;
  
  td, th {
    border: 1px solid;
    padding: 5px;
  }
  
  td {
    padding: 10px 25px;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
    }
  }
  
  thead > tr {
    background-color: #c2c2c2;
  }
`
