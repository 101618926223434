import { useShopData } from '.'
import useMultipleRequests from './useMultipleRequests'

export const useCatTree = (categories) => {
  const { storefrontApiToken, isDemoUser } = useShopData() || {}
  if (isDemoUser) {
    const demoUserCategory = categories.map((category) => ({
      data: {
        site: {
          categoryTree: [{
            name: `Category${category}`,
            path: `/Category${category}/`,
            entityId: category,
            children: []
          }]
        }
      }
    }))
    return { catTrees: demoUserCategory, loading: false }
  }

  const getShop = () => window?.location?.origin // current domain
  const getToken = () => `Bearer ${storefrontApiToken}`
  const reqObjects = categories.map(category => ({
    type: 'GQL',
    url: getShop() + '/graphql',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken()
    },
    body: {
      query: `
        query CategoryTreeItem ($entityId: Int) {
          site {
            categoryTree (rootEntityId: $entityId ) {
              ...CategoryFields
              children {
                ...CategoryFields
                children {
                  ...CategoryFields
                  children {
                    ...CategoryFields
                  }
                }
              }
            }
          }
        }

        fragment CategoryFields on CategoryTreeItem {
          name
          path
          entityId
        }
      `,
      variables: {
        entityId: category
      }
    }
  }))
  const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false, // Disable Automatic Revalidations
    refreshInterval: 1000 * 60 * 30 // 30 min
  }
  const resultFetchCategories = useMultipleRequests(reqObjects, swrOptions)
  const { data: categoriesData, error: categoriesError } = resultFetchCategories

  return { catTrees: categoriesData, loading: !categoriesData && !categoriesError, error: categoriesError }
}
