import styled from 'styled-components'
import { useShopData } from '../hooks'
import React, { useState } from 'react'
import { Alert, Aside, FitResults } from '../components'
import { widgetAddedMoreThanOnce } from '../utils'

export const Results = () => {
  const showDOMError = widgetAddedMoreThanOnce()
  const [showFilters, setShowFilters] = useState(false)
  const shopData = useShopData()
  const { suredoneID } = shopData || {}

  if (showDOMError) {
    return (
      <Alert color='error'>
        <h2>
          You have added the SureDone widget more than once on this page. <br /><br />
          Make sure there is only 1 widget per page, save settings and refresh the site.
        </h2>
      </Alert>
    )
  }

  if (!suredoneID) {
    return (
      <Alert color='error'>
        <h2>
          You have not added the SureDone ID for this page. <br /><br />
          Use <code>688015</code> as ID if you are testing and want to see demo data.
        </h2>
      </Alert>
    )
  }

  return (
    <Wrapper shopify className='fitment-results'>
      <Aside showFilters={showFilters} setShowFilters={setShowFilters} />
      <FitResults>
        <Button
          onClick={() => setShowFilters(s => !s)}
          className='show-filters-button btn button'
        >
          Filter Results
        </Button>
      </FitResults>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
`

const Button = styled.button`
  @media (min-width: 577px) {
    display: none;
  }
`
