import styled from 'styled-components'
import { useCustomYMMSearch, useFitment, useQueryParams } from '../hooks'
import React, { useState } from 'react'
import { Aside, CategoryYMMLabel, FitResults } from '../components'
import { showOriginalViewQParam } from '../utils'

export const CategoriesResults = () => {
  const storefrontCatPage = document.getElementById('SD-CollectionPage')

  const { year, make, model } = useCustomYMMSearch()
  const { results } = useFitment()
  const qparams = useQueryParams()
  const showOriginalView = qparams.get(showOriginalViewQParam) === 'true' || !year || !make || !model

  const { totalFacetedProducts: resultsCount } = results || {}
  const amountOfResults = resultsCount?.[0]?.total
  const anyResult = amountOfResults && amountOfResults > 0
  if (showOriginalView && storefrontCatPage) {
    storefrontCatPage.style.display = 'block'
  }
  const [showFilters, setShowFilters] = useState(false)

  return (
    <>
      {!showOriginalView && <CategoryYMMLabel anyResult={anyResult} year={year} make={make} model={model} />}
      <Wrapper shopify className={`fitment-category-results ${showOriginalView && 'inactive'}`}>
        <Aside showFilters={showFilters} setShowFilters={setShowFilters} />
        <FitResults>
          <Button
            className='show-filters-button btn button'
            onClick={() => setShowFilters(s => !s)}
          >
            Filter Results
          </Button>
        </FitResults>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  &.inactive {
    display: none;
  }
`

const Button = styled.button`
  @media (min-width: 577px) {
    display: none;
  }
`
