import { useQueryParams, useMyGarage } from '.'

export const useCustomYMMSearch = () => {
  const replaceChars = (string) => string?.replaceAll('+', ' ')
  const qparams = useQueryParams()
  const { lastYMMSearch } = useMyGarage()
  const { year, make, model } = { year: qparams.get('year'), make: qparams.get('make'), model: qparams.get('model') }
  if (!year && !make && !model && lastYMMSearch) {
    const yearGarage = lastYMMSearch?.year?.value
    const makeGarage = lastYMMSearch?.make?.value
    const modelGarage = lastYMMSearch?.model?.value
    return ({ year: Number(yearGarage), make: makeGarage, model: modelGarage })
  }
  return ({ year: Number(year), make: replaceChars(make), model: replaceChars(model) })
}
