import React from 'react'
import styled from 'styled-components'
import { Dialog } from '@headlessui/react'

export const Modal = ({ isOpen = false, setIsOpen, title = '', className, children }) => {
  const closeModal = () => setIsOpen(false)

  return (
    <Dialog open={isOpen} onClose={closeModal} className={`sd-modal ${className || ''}`}>
      <Backdrop aria-hidden='true' />
      <StylesWrapper>
        <Dialog.Panel className='panel'>
          {title && <Dialog.Title className='title'>{title}</Dialog.Title>}
          <button className='close-modal' onClick={closeModal}>✕</button>
          {children}
        </Dialog.Panel>
      </StylesWrapper>
    </Dialog>
  )
}

const StylesWrapper = styled.div`
  inset: 0;
  padding: 5%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 9999999999999999;

  .panel {
    width: 100%;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;

    .title {
      margin-bottom: 15px;
    }

    .close-modal {
      top: 16px;
      right: 16px;
      padding: 4px;
      border: none;
      cursor: pointer;
      font-size: 18px;
      background: none;
      position: absolute;
      font-weight: bolder;
    }
  }
`

const Backdrop = styled.div`
  inset: 0;
  position: fixed;
  z-index: 999999999999999;
  background-color: rgb(0 0 0 / 30%);
`
