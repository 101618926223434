import axios from 'axios'
import useSWR from 'swr'
import { YMM_URI } from '../utils'

const PATH = 'storefront/ymm'

const useYmm = (suredoneID, storefront, domain, year, make) => {
  const fetcher = async () => {
    const { data } = await axios({
      url: `${YMM_URI}/${PATH}`,
      method: 'GET',
      params: { suredoneID, storefront, domain, year, make }
    })
    return data
  }

  const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false, // Disable Automatic Revalidations
    refreshInterval: 1000 * 60 * 30 // 30 min
  }

  const { data: dataYear, error: errorYear, mutate: mutateYear } = useSWR(`${PATH}`, fetcher, swrOptions)

  const { data: dataMake, error: errorMake, mutate: mutateMake } = useSWR(year ? `${PATH}-${year}` : null, fetcher, swrOptions)

  const { data: dataModel, error: errorModel, mutate: mutateModel } = useSWR(year && make ? `${PATH}-${year}-${make}` : null, fetcher, swrOptions)

  return { dataYear, errorYear, mutateYear, dataMake, errorMake, mutateMake, dataModel, errorModel, mutateModel }
}

export { useYmm }
