import { useEffect } from 'react'
import { isDemoUser } from '../utils'
import { useLazyQuery } from '@apollo/client'
import { GET_STOREFRONT_TOKEN } from '../queries/Fitment'

export const useShopData = () => {
  const shopData = window?.shopData
  const isDemo = isDemoUser(shopData?.suredoneID)
  const [getStorefrontApiToken] = useLazyQuery(GET_STOREFRONT_TOKEN, {
    onCompleted: res => {
      if (res?.getStorefrontApiToken?.storefrontApiToken) {
        window.shopData = { storefrontApiToken: res?.getStorefrontApiToken?.storefrontApiToken, ...shopData }
      }
    }
  })

  useEffect(() => {
    if (!isDemo) {
      if (shopData?.storefront === 'bigcommerce' && !shopData?.storefrontApiToken) { // only for BC saves token in window.shopData
        const domain = window?.location?.origin
        getStorefrontApiToken({ variables: { domain } })
      }
    }
  }, [])

  if (shopData?.suredoneID) {
    return { ...shopData, isDemoUser: isDemo }
  }

  return { error: 'suredoneID not provided' }
}
