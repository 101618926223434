import React from 'react'
import styled from 'styled-components'
import { FitResults } from './FitResults'

// This component is instantiated when you use the <div id="sd-fitment-results-products"></div> tag
export const ResultsProducts = () => {
  const handleClick = () => {
    const aside = document.querySelector('#sd-fitment-results-filters aside')
    const showFiltersButton = document.querySelector('#sd-fitment-results-products .show-filters-button')

    if (aside && showFiltersButton) {
      aside.classList.add('show-filters')
    }
  }

  return (
    <FitResults>
      <Button onClick={handleClick} className='show-filters-button btn button'>
        Filter Results
      </Button>
    </FitResults>
  )
}

const Button = styled.button`
  @media (min-width: 577px) {
    display: none;
  }
`
