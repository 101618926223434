import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { configs, replaceForUrl } from '../utils'

export const useCustomNavigation = (navigationConfigs) => {
  const { useWindowToNavigate } = navigationConfigs || {}
  const navigate = useNavigate()

  const customNavigate = useCallback((storefront, year, make, model) => {
    const root = configs?.[storefront]?.rootToRedirect
    const resultPagePrefix = configs?.[storefront]?.resultPagePrefix
    const params = `?year=${year.value}&make=${replaceForUrl(make.value)}&model=${replaceForUrl(model.value)}`
    const link = `/${root}/result${resultPagePrefix}${params}`
    const forceWindowToNavigate = !window.location.href.split('/').includes('fitment')
    if (useWindowToNavigate || forceWindowToNavigate) { // Full reload of ALL page
      window.location = window.location.origin + link
    } else { // react redirect, just widget routing
      navigate(link)
    }
  }, [navigate])

  return customNavigate
}
