import React from 'react'
import styled from 'styled-components'
import { useShopData, useMyGarage, useCustomNavigation } from '../hooks'

export const MenuItemGarage = ({ ymm, useWindowToNavigate }) => {
  const { addMyGarageValue, removeGarageValue } = useMyGarage()
  const customNavigate = useCustomNavigation({ useWindowToNavigate })
  const shopData = useShopData()
  const { storefront } = shopData || {}
  const { year, make, model } = ymm

  const handleClickLink = () => {
    addMyGarageValue(ymm)
    customNavigate(storefront, year, make, model)
  }
  return (
    <MenuItem>
      <a onClick={handleClickLink}>{`${year?.value} ${make?.value} ${model?.value}`}</a>
      <Button onClick={() => removeGarageValue(ymm)}>x</Button>
    </MenuItem>
  )
}

const MenuItem = styled.div`
  display: flex;
  padding: 2px 0;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  a {
    width: 100%;
    padding: 2px;
    color: black;
    display: block;
    font-size: 14px;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
`

const Button = styled.button`
  border: none;
  color: black;
  cursor: pointer;
  font-weight: 700;
  padding: 4px 8px;
  background: none;
  border-radius: 3px;

  &:hover {
    color: #fff;
    background-color: #000;
  }
`
