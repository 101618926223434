import useSWR from 'swr'
import axios from 'axios'
import { useShopData } from '.'
import { getClientURI } from '../utils'

const fetcher = reqObject => {
  const { type, url, body, headers } = reqObject
  if (type === 'REST') return axios.get(url).then(res => res.data)
  if (type === 'GQL') {
    return axios({
      url,
      headers,
      data: body,
      method: 'post'
    }).then(res => res.data)
  }
}

/**
 * @param {object} item fitment item
 * @returns
 */
export const useProduct = (item, initialFetch = true) => {
  let reqObject
  const { storefront, suredoneID, storefrontApiToken, isDemoUser } = useShopData() || {}
  const { url, path, guid, sku } = item || {}

  const handleShopify = () => {
    reqObject = {
      type: 'REST',
      url: `${path}${url}.json`
    }
  }

  const handleBigcommerce = () => {
    const getShop = () => window?.location?.origin // current domain
    const getToken = () => `Bearer ${storefrontApiToken}`

    reqObject = {
      type: 'GQL',
      url: getShop() + '/graphql',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: {
        query: `
          query getProductBySKU($sku: String) {
            site {
              product(sku: $sku) {
                id
                sku
                entityId
                path
                name
                description
                prices {
                  price {
                    value
                  }
                }
                images {
                  edges {
                    node {
                      url(width: 300, height: 300)
                      altText
                    }
                  }
                }
              }
            }
          }
      `,
        variables: {
          sku: `${sku}`
        }
      }
    }
  }

  const handleSuredone = () => {
    reqObject = {
      type: 'GQL',
      url: getClientURI(), // 'https://app.suredone.com/public/fitment/graphql',
      headers: { 'Content-Type': 'application/json' },
      body: {
        query: `
          query getProduct ($userId: Int!, $guid: String) {
            getProduct (userId: $userId,guid:$guid){
              guid
              title
              price
              brand
              media
              uri
            }
          }
        `,
        variables: {
          guid: `${guid}`,
          userId: Number(suredoneID)
        }
      }
    }
  }

  switch (storefront) {
    case 'shopify':
      isDemoUser ? handleSuredone() : handleShopify()
      break
    case 'bigcommerce':
      isDemoUser ? handleSuredone() : handleBigcommerce()
      break
    case 'storefront':
      handleSuredone()
      break
    default:
      break
  }

  const { data, error } = useSWR(reqObject, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false, // Disable Automatic Revalidations
    refreshInterval: 1000 * 60 * 30 // 30 min
  })

  return { data: standarizeData(data, item, storefront, isDemoUser), error, loading: !data && !error }
}

const prepareShopify = (data, item) => {
  const { brand, url, price: sdPrice } = item
  const { product } = data || {}
  const { title, image, variants } = product || {}
  const { alt, src } = image || {}
  const shopifyPrice = variants?.[0]?.price || sdPrice

  if (!title) return null

  return {
    url,
    title,
    price: shopifyPrice || sdPrice,
    brand,
    image: {
      alt,
      src
    }
  }
}

const prepareBigcommerce = (data, item) => {
  const { data: { site } } = data || {}

  const { brand } = item
  const { product } = site || {}
  const { images, name, path, prices } = product || {}
  const { edges } = images || {}
  const { node } = edges?.[0] || {}
  const { url, altText } = node || {}
  const { price } = prices || {}

  if (!name) return null

  return {
    url: path,
    title: name,
    price: price?.value,
    brand,
    image: {
      src: url,
      alt: altText
    }
  }
}

const prepareSuredone = (data, isDemoUser, storefront) => {
  const { data: { getProduct } } = data || {}
  const { title, price, brand, media, uri } = getProduct || {}

  const generateUrl = uri => {
    if (storefront === 'shopify') return `/products/${uri}`
    if (isDemoUser) return `/${uri.split('-').slice(0, -1).join('-')}`
    return `/${uri}`
  }

  return {
    url: generateUrl(uri),
    title,
    price: price && Number(price).toFixed(2),
    brand,
    image: {
      src: media?.[0],
      alt: title
    }
  }
}

const standarizeData = (data, item, storefront, isDemoUser) => {
  if (!data) return undefined

  switch (storefront) {
    case 'shopify':
      return isDemoUser ? prepareSuredone(data, isDemoUser, storefront) : prepareShopify(data, item)
    case 'bigcommerce':
      return isDemoUser ? prepareSuredone(data, isDemoUser, storefront) : prepareBigcommerce(data, item)
    case 'storefront':
      return prepareSuredone(data)
    default:
      break
  }
}
