import React, { createContext } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'
import isEqual from 'lodash.isequal'

export const GarageContext = createContext(null)

const GarageProvider = ({ children }) => {
  const defaultValue = []
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [myGarageValue, setStorageValue, clearStorageValue] = useLocalStorage('sd-fits-garage', defaultValue)

  const addMyGarageValue = newValue => {
    const _newState = [newValue, ...myGarageValue.filter((value) => !isEqual(newValue, value))]
    const [, ...rest] = _newState
    const newState = _newState?.length > 20 ? rest : _newState
    setStorageValue(newState)
  }

  const removeGarageValue = (objectToRemove) => {
    const newValue = myGarageValue.filter((value) => !isEqual(objectToRemove, value))
    setStorageValue(newValue)
  }

  const lastYMMSearch = myGarageValue && myGarageValue.length > 0 && myGarageValue[0]

  if (lastYMMSearch) {
    // Exposes the lastYMMSearch in window.sdFitmentData.lastYMMSearch
    const lastYMM = { year: lastYMMSearch.year?.value, make: lastYMMSearch.make?.value, model: lastYMMSearch.model?.value }
    if (window.sdFitmentData) {
      window.sdFitmentData.lastYMMSearch = lastYMM
    } else {
      window.sdFitmentData = {}
      window.sdFitmentData.lastYMMSearch = lastYMM
    }
  }

  const clearGarage = () => clearStorageValue('sd-fits-garage')

  const value = { myGarageValue, lastYMMSearch, addMyGarageValue, removeGarageValue, clearGarage }
  return <GarageContext.Provider value={value}>{children}</GarageContext.Provider>
}

export { GarageProvider }
