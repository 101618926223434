import { useContext } from 'react'
import { GarageContext } from '../provider/GarageProvider'

const useMyGarage = () => {
  const context = useContext(GarageContext)
  if (context === undefined) {
    throw new Error('useMyGarage must be used within a GarageProvider')
  }
  return context
}

export { useMyGarage }
