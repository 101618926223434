import React from 'react'
import styled from 'styled-components'
import { useShopData } from '../hooks'

export const GoButton = ({ onClick, ...props }) => {
  const { primaryColor } = useShopData() || {}
  const { disabled } = props || {}

  return (
    <Button
      {...props}
      onClick={onClick}
      color={primaryColor}
      className={`go-button btn button ${disabled ? 'disabled' : ''}`}
    >
      GO
    </Button>
  )
}

const Button = styled.button`
  margin: 0;
  width: 100%;
  color: #fff;
  height: 38px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  padding: 6px 12px;
  border-radius: 3px;
  background-color: ${({ color }) => color || '#000'};
  opacity: ${({ disabled }) => disabled ? '0.7' : '1'};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
`
