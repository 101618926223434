import { useEffect } from 'react'
import { configs, replaceForUrl } from '../utils'
import { useCustomYMMSearch, useShopData } from '../hooks'

const getResultsPageLink = ({ storefront, year, make, model }) => {
  const { origin } = window?.location
  const root = configs?.[storefront]?.rootToRedirect
  const resultPagePrefix = configs?.[storefront]?.resultPagePrefix
  const params = `?year=${year}&make=${replaceForUrl(make)}&model=${replaceForUrl(model)}`
  const link = `/${root}/result${resultPagePrefix}${params}`

  return origin + link
}

export const HomeLink = () => {
  const { year, make, model } = useCustomYMMSearch()
  const { storefront } = useShopData() || {}

  useEffect(() => {
    // Gets the query selector value present in sd-fitment-handle-home-link
    const suredoneTag = document?.getElementById('sd-fitment-handle-home-link')
    const querySelector = suredoneTag.getAttribute('data-query-selector')
    // Gets the logo element to handle href
    const logoLink = document?.querySelector(querySelector)

    if (year && make && model) {
      logoLink.href = getResultsPageLink({ storefront, year, make, model })
    }
  }, [])

  return null
}
