import React, { useState } from 'react'
import { Modal } from './Modal'
import { InitialSearch } from '../scenes'

// See https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/SearchModal.md
export const SearchModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className='btn button sd-open-search'
      >
        Click to Search for a Vehicle
      </button>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title='Search Vehicle'
        className='sd-search-modal'
      >
        <InitialSearch />
      </Modal>
    </>
  )
}
