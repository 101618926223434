import { appEndpoint } from './config'

export const getClientURI = () => {
  return `${appEndpoint}/public/fitment/graphql`
}

export const YMM_URI = `${appEndpoint}/public/fitment/rest`

const DEMOUSER = 688015
const CHANNELS_TO_CHECK_PAYMENT = ['shopify']

export const configs = {
  shopify: {
    rootToRedirect: 'pages/fitment',
    resultPagePrefix: ''
  },
  bigcommerce: {
    rootToRedirect: 'fitment',
    resultPagePrefix: ''
  },
  storefront: {
    rootToRedirect: 'fitment',
    resultPagePrefix: '/'
  }
}

export const showOriginalViewQParam = 'sd-sov'

export const replaceForUrl = (string) => string?.replaceAll(' ', '+').toLowerCase()

export const isDemoUser = (userId) => Number(userId) === DEMOUSER

export const widgetAddedMoreThanOnce = () => document.querySelectorAll('#sd-root')?.length > 1

export const shouldCheckPayment = (storefront, isDemoUser) => CHANNELS_TO_CHECK_PAYMENT.includes(storefront) && !isDemoUser

export const shouldPreventRedirect = () => !!document?.querySelector('[data-category-product-pages-prevent-redirect]')

export const shouldApplyOtherByDefault = () => !!document?.querySelector('[apply-other-by-default]')

export const getHeadersLabels = () => document?.querySelector('#sd-advanced-fits-table')?.getAttribute('data-columns-labels')

export const getHiddenColumns = () => document?.querySelector('#sd-advanced-fits-table')?.getAttribute('data-columns-hidden')

export const getHeadersOrder = () => document?.querySelector('#sd-advanced-fits-table')?.getAttribute('data-columns-order')

export const useLabel = header => {
  try {
    const labels = JSON.parse(getHeadersLabels())
    return labels?.[header] || header
  } catch (error) {
    return 'Error: Malformed JSON in data-columns-labels'
  }
}

export const entireColumnIsEmpty = (header, rawData) => {
  const atLeastOneValue = rawData?.find(i => i?.[header] != null && i?.[header] != 0)
  return !atLeastOneValue
}

export const shouldHideColumn = header => {
  try {
    const hiddenColumns = getHiddenColumns()?.split(',')?.map(i => i.trim())
    return hiddenColumns?.includes(header)
  } catch (error) {
    return 'Error: Malformed headers in data-columns-hidden'
  }
}

export const sortHeaders = originalHeaders => {
  const desiredOrder = getHeadersOrder()?.split(',')?.map(i => i.trim())

  if (!desiredOrder || desiredOrder?.[0] === '') return originalHeaders

  try {
    const filteredArray = originalHeaders.filter(i => !desiredOrder.includes(i.trim()))
    return desiredOrder?.concat(filteredArray)
  } catch (error) {
    return 'Error: Malformed headers in data-columns-order'
  }
}

export const sortGroups = (groups) => {
  if (!groups) return groups

  return groups.sort((a, b) => {
    const [yearA, makeA, modelA] = Object.keys(a)?.[0]?.split('*')
    const [yearB, makeB, modelB] = Object.keys(b)?.[0]?.split('*')

    if (yearA !== yearB) {
      return yearB - yearA
    } else if (makeA !== makeB) {
      return makeA.localeCompare(makeB)
    } else {
      return modelA.localeCompare(modelB)
    }
  })
}

export const convertYMMKeysToLowerCase = (obj) => {
  const newObj = {}
  for (const key in obj) {
    const lowerKey = key?.toLowerCase()
    if (['year', 'make', 'model'].includes(lowerKey)) {
      newObj[lowerKey] = obj[key]
    } else {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

export const completeDataForMissingHeaders = (headers, data) => {
  return data?.map(obj => {
    const newObj = { ...obj }

    headers?.forEach(h => {
      if (!(h in newObj)) {
        newObj[h] = ''
      }
    })

    return newObj
  })
}
