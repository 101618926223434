import React from 'react'
import { MenuItemGarage } from '.'
import styled from 'styled-components'
import { useShopData, useMyGarage } from '../hooks'

export const MyGarageButton = ({ useWindowToNavigate, showAsLink }) => {
  const { myGarageValue, clearGarage } = useMyGarage()
  const { primaryColor } = useShopData() || {}

  const Content = () => {
    if (myGarageValue.length > 0) {
      return (
        <>
          {myGarageValue.map((ymm, i) => <MenuItemGarage key={i} ymm={ymm} useWindowToNavigate={useWindowToNavigate} />)}
          <ClearButton className='clear-garage-btn' onClick={clearGarage} color={primaryColor}>
            Clear garage
          </ClearButton>
        </>
      )
    }
    return <p style={{ textAlign: 'center', padding: '8px 4px' }}>Store your vehicle by searching</p>
  }

  return (
    <Dropdown className='sd-my-garage-btn'>
      <Button className='drop-btn' color={primaryColor} showAsLink={showAsLink}>
        My garage
      </Button>
      <div className='drop-content'>
        <Content />
      </div>
    </Dropdown>
  )
}

const Dropdown = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;

  .drop-content {
    width: 100%;
    padding: 4px;
    display: none;
    z-index: 999999999;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }

  &:hover .drop-content {
    display: block;
  }
`

const Button = styled.button`
  margin: 0;
  width: 100%;
  border: none;
  cursor: pointer;
  font-weight: 700;
  border-radius: 3px;
  height: ${({ showAsLink }) => showAsLink ? 'auto' : '38px'};
  color: ${({ showAsLink }) => showAsLink ? 'inherit' : '#fff'};
  padding: ${({ showAsLink }) => showAsLink ? '0' : '6px 12px'};
  text-decoration: ${({ showAsLink }) => showAsLink ? 'underline' : 'none'};
  background-color: ${({ color, showAsLink }) => showAsLink ? 'transparent' : (color || '#000')};
`

const ClearButton = styled.button`
  margin: 0;
  padding: 0;
  color: #000;
  width: 100%;
  height: 25px;
  border: none;
  cursor: pointer;
  margin-top: 8px;  
  font-weight: 700;
  background: #fff;
  border-radius: 3px;
  border: 1px solid ${({ color }) => color || '#000'};
`
