import React from 'react'
import styled from 'styled-components'

export const Alert = ({ color, children }) => {
  return (
    <Wrapper color={color}>
      {children}
    </Wrapper>
  )
}

const colors = {
  success: '#28a745',
  error: '#dc3545',
  default: '#000'
}

const Wrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding: 30px 40px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  border: 3px dashed ${({ color }) => colors[color] || '#000'};
`
