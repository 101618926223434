import React from 'react'
import ReactDOM from 'react-dom'
import { getClientURI } from './utils'
import { InitialSearch } from './scenes'
import { Results } from './scenes/Results'
import reportWebVitals from './reportWebVitals'
import { HomeLink } from './components/HomeLink'
import { SearchPage } from './scenes/SearchPage'
import { SearchModal } from './components/SearchModal'
import { GarageProvider } from './provider/GarageProvider'
import { ResultsFilters } from './components/ResultsFilters'
import { EngagedVehicle } from './components/EngagedVehicle'
import { ResultsProducts } from './components/ResultsProducts'
import { CategoriesResults } from './scenes/CategoriesResults'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { FixedInitialSearch } from './components/FixedInitialSearch'
import { SearchWithGoButton } from './components/SearchWithGoButton'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { FitsTable, FitsTableAdvanced, FitsYourVehicle, MyGarageButton } from './components'
import './index.css'

const client = new ApolloClient({
  uri: getClientURI(),
  cache: new InMemoryCache()
})

// window.shopData = { suredoneID: 688015, storefront: 'bigcommerce' }
// window.sdFitmentData = { sku: '18307560778', category: '104', categoryName: 'Categoria random' }
// window.sdFitmentData = { searchResultSKUs: ['72340SZAA13', '45251SR3A11'] }

const sdRootElement = document.getElementById('sd-root')
const sdFitsTable = document.getElementById('sd-fitment-chart-root')
const sdAdvancedFitsTable = document.getElementById('sd-advanced-fits-table')
const sdFYVRoot = document.getElementById('sd-fitment-fyv-root')
const sdCategoryRoot = document.getElementById('sd-fitment-categories-root')
const sdSearchPageRoot = document.getElementById('sd-fitment-search-page-root')
const sdSearchModalRoot = document.getElementById('sd-fitment-search-modal')
const sdFixedInitialSearch = document.getElementById('sd-fitment-fixed-initial-search')
const sdSearchWithGoButton = document.getElementById('sd-fitment-search-with-go-button')
const sdResultsFilters = document.getElementById('sd-fitment-results-filters')
const sdResultsProducts = document.getElementById('sd-fitment-results-products')
const sdEngagedVehicle = document.getElementById('sd-fitment-engaged-vehicle')
const sdHomeLink = document.getElementById('sd-fitment-handle-home-link')
const sdMyGarage = document.getElementById('sd-fitment-my-garage')

const ProvidersWrapper = ({ children }) => (
  <React.StrictMode>
    <ApolloProvider client={client}>
      <GarageProvider>
        <BrowserRouter>
          <Routes>
            {children}
          </Routes>
        </BrowserRouter>
      </GarageProvider>
    </ApolloProvider>
  </React.StrictMode>
)

if (sdRootElement) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/' element={<InitialSearch />} />
      <Route path='/:p1' element={<InitialSearch />} />
      <Route path='/:p1/:p2' element={<InitialSearch />} />
      <Route path='/:p1/result' element={<Results />} />
      <Route path='/:p1/fitment-result' element={<Results />} />
      <Route path='/:p1/:p2/result' element={<Results />} />
    </ProvidersWrapper>,
    sdRootElement
  )
}

// "This product fits your vehicle" – see https://app.suredone.com/fitment-advance-instructions/bigcommerce#AddFYV
if (sdFYVRoot) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<FitsYourVehicle />} />
    </ProvidersWrapper>,
    sdFYVRoot
  )
}

// Fits table
if (sdFitsTable) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<FitsTable />} />
    </ProvidersWrapper>,
    sdFitsTable
  )
}

// Advanced fits table (with ACES and more attributes)
if (sdAdvancedFitsTable) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<FitsTableAdvanced />} />
    </ProvidersWrapper>,
    sdAdvancedFitsTable
  )
}

if (sdCategoryRoot) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<CategoriesResults />} />
    </ProvidersWrapper>,
    sdCategoryRoot
  )
}

if (sdSearchPageRoot) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<SearchPage />} />
    </ProvidersWrapper>,
    sdSearchPageRoot
  )
}

// Please read https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/SearchModal.md
if (sdSearchModalRoot) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<SearchModal />} />
    </ProvidersWrapper>,
    sdSearchModalRoot
  )
}

// Please read https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/FixedInitialSearch.md
if (sdFixedInitialSearch) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<FixedInitialSearch />} />
    </ProvidersWrapper>,
    sdFixedInitialSearch
  )
}

// Please read https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/SearchWithGoButton.md
if (sdSearchWithGoButton) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<SearchWithGoButton />} />
    </ProvidersWrapper>,
    sdSearchWithGoButton
  )
}

// Please read https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/SplittingResultsPage.md
if (sdResultsFilters) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<ResultsFilters />} />
    </ProvidersWrapper>,
    sdResultsFilters
  )
}

// Please read https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/SplittingResultsPage.md
if (sdResultsProducts) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<ResultsProducts />} />
    </ProvidersWrapper>,
    sdResultsProducts
  )
}

// Please read https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/EngagedVehicle.md
if (sdEngagedVehicle) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<EngagedVehicle />} />
    </ProvidersWrapper>,
    sdEngagedVehicle
  )
}

// Please read https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/HomeLink.md
if (sdHomeLink) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<HomeLink />} />
    </ProvidersWrapper>,
    sdHomeLink
  )
}

// Please read https://github.com/suredone/suredone-ext/blob/master/wiki/fitment/MyGarage.md
if (sdMyGarage) {
  ReactDOM.render(
    <ProvidersWrapper>
      <Route path='/*' element={<MyGarageButton useWindowToNavigate />} />
    </ProvidersWrapper>,
    sdMyGarage
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
