import { useQuery } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'
import { useMyGarage, useShopData } from '../hooks'
import { GET_PRODUCT_YMM_BY_SKU } from '../queries/Fitment'
import { Spinner } from './Spinner'

const FitsYourVehicleLabel = ({ fits, year, make, model }) => {
  return (
    <FitWrapper className='product-fit-wrapper'>
      <div className='fyv-icon'>
        <span>{fits ? '✔️' : '❌'}</span>
      </div>
      <div className='fyv-content'>
        <p style={{ margin: 0 }}>
          This product {fits ? 'fits' : 'does not fit'} your last search <b>{year} {make} {model}</b>
        </p>
      </div>
    </FitWrapper>
  )
}

export const FitsYourVehicle = () => {
  const shopData = useShopData()
  const { suredoneID, storefront } = shopData || {}
  const { lastYMMSearch } = useMyGarage()
  const sku = window?.sdFitmentData?.sku
  const { data, loading, error } = useQuery(GET_PRODUCT_YMM_BY_SKU, {
    variables: {
      userId: Number(suredoneID),
      domain: window?.location?.origin,
      storefront,
      sku
    }
  })
  const { getProductYMMBySku } = data || {}
  const { ymmci } = getProductYMMBySku || {}
  const fitsVehicle = lastYMMSearch && ymmci && ymmci.length > 0 && ymmci.some((ymm) => lastYMMSearch.year.value === ymm.year && lastYMMSearch.make.value === ymm.make && lastYMMSearch.model.value === ymm.model)

  if (!sku || (ymmci && ymmci.length === 0) || error) return null
  if (loading) return <Spinner />
  if (lastYMMSearch) return <FitsYourVehicleLabel fits={fitsVehicle} year={lastYMMSearch.year.value} make={lastYMMSearch.make.value} model={lastYMMSearch.model.value} />

  return null
}

const FitWrapper = styled.div`
  gap: 10px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: flex-start;
`
