import { useShopData, useQueryParams, useCustomYMMSearch } from '.'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { shouldApplyOtherByDefault, showOriginalViewQParam } from '../utils'
import { GET_RESULTS, GET_FACETS_MULTI } from '../queries/Fitment'

const pageSize = 48 // Amount of results shown per page
const offset = (pageSize, currentPage) => pageSize * currentPage

// facetsMulti = [{ name: 'bedsize', label: 'Bed Size' }]
const prepareFacets = ({ queryParams, storefront, facetsMulti = [] }) => {
  const keys = []
  const noFilteringKeys = ['page', 'categories', 'year', 'make', 'model', 'search_query', 'q', 'options[prefix]', showOriginalViewQParam]

  queryParams?.forEach((v, key) => {
    if (!noFilteringKeys.includes(key)) keys.push(key)
  })
  const groupsByKey = keys.map(k => queryParams?.get(k)?.split('*')?.map(i => {
    if (k === 'price') {
      const min = i.split('-')[0]
      const max = i.split('-')[1]
      return {
        filterType: 'AND',
        filters: [
          { key: k, opr: '>=', val: min },
          { key: k, opr: '<=', val: max }
        ]
      }
    }

    if (i === 'Other') {
      return {
        filterType: 'OR',
        filters: [
          { key: k, opr: '=', val: '' },
          { key: k, opr: '=', val: null }
        ]
      }
    }

    // In this case "Other" filter is also applied by default
    if (shouldApplyOtherByDefault()) {
      return {
        filterType: 'OR',
        filters: [
          { key: k, opr: '=', val: `${i}` },
          { key: k, opr: '=', val: '' },
          { key: k, opr: '=', val: null }
        ]
      }
    }

    // facets multi are custom facets where the value in Mongo is stored as array instead of string
    if (facetsMulti?.some(facet => facet?.label === k)) {
      const splittedValues = Array.from(new Set(i?.split('*')))
      return {
        filterType: 'OR',
        filters: [
          { key: k, opr: 'in', val: splittedValues }
        ]
      }
    }

    return { key: k, opr: '=', val: `${i}` }
  }))

  if (queryParams?.has('categories') || window?.sdFitmentData?.category) {
    const categories = queryParams?.get('categories') || window?.sdFitmentData?.category
    const splittedCategories = Array.from(new Set(categories?.split('*')))
    groupsByKey.push([{ key: 'categories', opr: 'in', val: storefront === 'bigcommerce' ? splittedCategories.map(x => Number(x)) : splittedCategories }])
  }

  return {
    filterType: 'AND',
    filters: groupsByKey?.map(i => ({ filterType: 'OR', filters: i }))
  }
}

/**
 * @param {{ useWindowToNavigate: boolean }} configs
 */
export const useFitment = (configs) => {
  const { useWindowToNavigate } = configs || {}
  const shopData = useShopData()
  const { suredoneID, storefront, instance } = shopData || {}
  const [searchParams, setSearchParams] = useSearchParams()
  const queryParams = useQueryParams()
  const currentPage = Number(queryParams.get('page'))
  const { year, make, model } = useCustomYMMSearch()
  const origin = window?.location?.origin
  const pathname = window?.location?.pathname

  const { data: dataFacetsMulti } = useQuery(GET_FACETS_MULTI, {
    variables: {
      userId: Number(suredoneID),
      storefront,
      instance //  If the store uses an instance greater than 0, you must include it in window.shopData = {instance: 2, ...}
    }
  })
  const { getFacetsMulti: facetsMulti } = dataFacetsMulti || {}

  const { data: dataR, loading: loadingR, error: errorR } = useQuery(GET_RESULTS, {
    variables: {
      userId: Number(suredoneID),
      domain: origin,
      year: parseInt(year),
      make: make,
      model: model,
      filter: prepareFacets({ queryParams, storefront, instance, facetsMulti }),
      limit: pageSize,
      offset: offset(pageSize, currentPage),
      storefront
    }
  })

  const { getResults } = dataR || []
  const results = getResults?.[0] || {}

  const updateURL = (id, newVal) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString())
    if (!newVal) {
      updatedSearchParams.delete(id)
    } else {
      updatedSearchParams.set(id, newVal)
    }
    if (useWindowToNavigate) {
      window.location = origin + pathname + '?' + (updatedSearchParams.toString() || '')
    } else {
      setSearchParams(updatedSearchParams.toString())
    }
  }

  /**
   * @param {string} id
   * @param {string} val
   */
  const addFilter = (id, val) => {
    const currentVal = queryParams.get(id)
    const newVal = currentVal && id !== 'price' ? currentVal + '*' + val : val
    updateURL(id, newVal)
  }

  /**
   * @param {string} id
   * @param {string} val
   */
  const removeFilter = (id, val) => {
    const currentVal = queryParams.get(id)
    const parsed = currentVal?.split('*').map(i => i.replaceAll(',', ' '))
    const newVal = parsed?.filter(i => i !== val).join('*')
    updateURL(id, newVal)
  }

  return { queryParams, updateURL, addFilter, removeFilter, results, loadingResults: loadingR, errorResults: errorR, pageSize, currentPage, offset }
}
