import React from 'react'
import { useFitment } from '../hooks'
import { Spinner } from './Spinner'
import { FilterMenu, Item, Label } from '.'

export const FitCategoriesFilterGroup = ({ useWindowToNavigate }) => {
  const { results, loadingResults } = useFitment()
  const availableCategories = results?.categories

  return (
    <FilterMenu title='categories'>
      {availableCategories?.map((category, idx) => (loadingResults ? <Spinner /> : <CategoriesOptions useWindowToNavigate={useWindowToNavigate} category={category._id} key={idx} idx={idx} />))}
    </FilterMenu>
  )
}

const CategoriesOptions = ({ category, idx, useWindowToNavigate }) => {
  const { queryParams, addFilter, removeFilter } = useFitment({ useWindowToNavigate })
  const categories = queryParams.get('categories')
  const isChecked = val => {
    const parsed = categories?.split('*')?.map(i => i.replaceAll(',', ' '))
    if (!val) return !!parsed?.includes('Other')
    return !!parsed?.includes(val)
  }

  const handleCheck = (e, val) => {
    const wasChecked = e.target.checked
    if (wasChecked) { return addFilter('categories', val) }
    return removeFilter('categories', val)
  }

  if (!category) return null

  return (
    <Item className='menu__item' key={idx}>
      <Label check className='menu__value'>
        <input
          type='checkbox'
          checked={isChecked(`${category}`)}
          onChange={e => handleCheck(e, `${category}`)}
        />
        <span>
          {category}
        </span>
      </Label>
    </Item>
  )
}
