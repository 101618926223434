import React from 'react'
import styled from 'styled-components'
import { useMyGarage } from '../hooks'

export const ResetGarageButton = () => {
  const { clearGarage } = useMyGarage()

  const handleRefresh = () => {
    clearGarage()
    window?.location?.reload()
  }

  return (
    <Button onClick={handleRefresh} className='refresh-button'>
      Reset Vehicle
    </Button>
  )
}

// Hidden by default
const Button = styled.button`
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  height: auto;
  display: none; 
  color: inherit;
  cursor: pointer;
  font-weight: 700;
  border-radius: 3px;
  text-decoration: underline;
  background-color: transparent;
`
