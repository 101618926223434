import gql from 'graphql-tag'

export const GET_RESULTS = gql`
  query getResults ($userId: Int!, $domain: StrippedProtocolUrl!, $year: Int, $make: String, $model: String, $filter: JSONObject!, $targetOrganizationId: String, $offset: Int, $limit: Int, $storefront: FitmentChannels) {
    getResults (userId: $userId, domain: $domain, year: $year, make: $make, model: $model, filter: $filter, targetOrganizationId: $targetOrganizationId, offset: $offset, limit: $limit, storefront: $storefront)
  }
`

export const GET_STOREFRONT_TOKEN = gql`
  query getStorefrontApiToken ($domain: StrippedProtocolUrl!, $userId: Int) {
    getStorefrontApiToken (domain: $domain, userId: $userId) {
      storefrontApiToken
    }
  }
`

export const GET_FACETS_MULTI = gql`
  query getFacetsMulti ($userId: Int!, $storefront: FitmentChannels, $instance: Int) {
    getFacetsMulti (userId: $userId, storefront: $storefront, instance: $instance)
  }
`

export const CHECK_STOREFRONT_STATUS = gql`
  query checkStorefrontStatus ($shop: StrippedProtocolUrl, $domain: StrippedProtocolUrl) {
    checkStorefrontStatus (shop: $shop, domain: $domain) {
      status
      confirmationUrl
    }
  }
`

export const GET_PRODUCT_YMM_BY_SKU = gql`
  query getProductYMMBySku ($userId: Int!, $domain: StrippedProtocolUrl!, $storefront: FitmentChannels, $sku:String) {
    getProductYMMBySku (userId: $userId, domain: $domain, storefront: $storefront, sku: $sku){
      _id
      ymmci {
        year
        make
        model
      }
    }
  }
`

export const GET_FITS_BY_ID = gql`
  query getFitsByID ($userId: Int!, $domain: StrippedProtocolUrl!, $storefront: FitmentChannels, $sku:String) {
    getFitsByID (userId: $userId, domain: $domain, storefront: $storefront, sku: $sku)
  }
`
