import { useEffect } from 'react'
import { useShopData } from './useShopData'
import { useLazyQuery } from '@apollo/client'
import { shouldCheckPayment } from '../utils'
import { CHECK_STOREFRONT_STATUS } from '../queries/Fitment'

export const usePayment = () => {
  const domain = window?.location?.origin
  const shopData = useShopData()
  const { storefront, isDemoUser } = shopData || {}
  const [checkStorefrontStatus, { data, error, loading }] = useLazyQuery(CHECK_STOREFRONT_STATUS)

  useEffect(() => {
    if (shouldCheckPayment(storefront, isDemoUser)) checkStorefrontStatus({ variables: { domain } })
  }, [])

  return {
    data,
    error,
    loading
  }
}
