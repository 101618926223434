import React from 'react'
import styled from 'styled-components'
import { useFitment, useCustomYMMSearch, useShopData } from '../hooks'
import {
  FilterGroup, FixedFilterGroup, Spinner, MyGarageButton,
  FitCategoriesFilterGroupBigcommerce, FitCategoriesFilterGroup,
  Hr
} from '.'

export const Aside = ({ useWindowToNavigate, showFilters, setShowFilters = () => { } }) => {
  const { results, loadingResults } = useFitment()
  const { year, make, model } = useCustomYMMSearch()
  const { storefront } = useShopData() || {}
  const groups = results && Object.keys(results)?.filter(i => i !== 'raw' && i !== 'totalFacetedProducts' && i !== 'categories')
  const anyCategories = Object.keys(results).indexOf('categories') >= 0

  return (
    <StyledAside className={`${showFilters ? 'show-filters' : ''}`}>
      <Wrapper>
        <Button
          className='hide-filters-button btn button'
          onClick={() => setShowFilters(false)}
        >
          Close
        </Button>
      </Wrapper>
      <Nav>
        <FixedFilterGroup title='Year' value={year} />
        <FixedFilterGroup title='Make' value={make} />
        <FixedFilterGroup title='Model' value={model} />
        <div className='sd-myGarageButton-Aside'>
          <MyGarageButton useWindowToNavigate={useWindowToNavigate} />
          <Hr />
        </div>
        {loadingResults
          ? <Spinner />
          : (
            <>
              {anyCategories && (storefront === 'bigcommerce')
                ? <FitCategoriesFilterGroupBigcommerce useWindowToNavigate={useWindowToNavigate} />
                : <FitCategoriesFilterGroup useWindowToNavigate={useWindowToNavigate} />}
              {groups?.map(i => <FilterGroup key={i} id={i} useWindowToNavigate={useWindowToNavigate} />)}
            </>
            )}
      </Nav>
    </StyledAside>
  )
}

const StyledAside = styled.aside`
  width: 20%;

  @media (max-width: 576px) {
    top: 0;
    width: 100%;
    left: -110%;
    z-index: 999;
    height: 100%;
    overflow: auto;
    position: fixed;
    transition: .3s ease;
    background-color: #fff;
    box-shadow: 0px -5px 20px #00000080;

    &.show-filters {
      left: 0;
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Button = styled.button`
  @media (min-width: 577px) {
    display: none;
  }
`

const Nav = styled.nav``
