import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { usePayment, useShopData, useYmm, useMyGarage, useCustomNavigation } from '../hooks'
import { Alert, FitSelect, Spinner, MyGarageButton } from '../components'
import { shouldCheckPayment, shouldPreventRedirect, widgetAddedMoreThanOnce } from '../utils'

const uniqueValues = (array) => [...new Set(array?.map(i => i?._id))]

export const InitialSearch = () => {
  const showDOMError = widgetAddedMoreThanOnce()
  const { origin, pathname } = window?.location
  const [searchParams] = useSearchParams()
  const shopData = useShopData()
  const { suredoneID, storefront, isDemoUser } = shopData || {}
  const customNavigate = useCustomNavigation()
  const { addMyGarageValue } = useMyGarage()
  const initialSelectState = {}
  const [selected, setSelected] = useState(initialSelectState)
  const { data: paymentData, loading: paymentLoading, error: paymentError } = usePayment()

  const { dataYear, dataMake, dataModel, errorYear, errorMake, errorModel } = useYmm(suredoneID, storefront, origin, selected?.year?.value, selected?.make?.value)

  const getOptions = key => {
    if (key === 'year') {
      const years = uniqueValues(dataYear)
      return years?.sort((a, b) => b - a)
    }
    if (key === 'make') {
      const makes = uniqueValues(dataMake)
      return makes?.sort()
    }
    if (key === 'model') {
      const models = uniqueValues(dataModel)
      return models?.sort()
    }
  }

  const handleChange = e => {
    const { type } = e || {}
    setSelected(prev => ({ ...prev, [type]: e }))
    if (type === 'year') {
      setSelected(prev => ({ ...prev, make: undefined, model: undefined }))
    }
    if (type === 'make') {
      setSelected(prev => ({ ...prev, model: undefined }))
    }
  }

  // When user clicks on preview store, Shopify and BC add extra params
  // https://origin/?_bt={...} for Shopify and https://origin/?ctk={...} for BC
  // This function detects if any of these parameters exist in the URL and removes them for the correct functioning of the widget
  useEffect(() => {
    const extraShopifyParam = searchParams?.get('_bt')
    const extraBigcommerceParam = searchParams?.get('ctk')
    if (extraShopifyParam || extraBigcommerceParam) window.location = origin
  }, [])

  useEffect(() => {
    const { year, make, model } = selected || {}
    if (model) {
      addMyGarageValue(selected)

      if (shouldPreventRedirect()) {
        window.location = origin + pathname
      } else {
        customNavigate(storefront, year, make, model)
      }
    }
  }, [selected, storefront, customNavigate])

  if (showDOMError) {
    return (
      <Alert color='error'>
        <h2>
          You have added the SureDone widget more than once on this page. <br /><br />
          Make sure there is only 1 widget per page, save settings and refresh the site.
        </h2>
      </Alert>
    )
  }

  if (!suredoneID) {
    return (
      <Alert color='success'>
        <h3 style={{ marginBottom: '10px' }}>The widget was successfully added 🎉</h3>
        <p style={{ width: '100%', margin: '5px 0' }}>Provide a valid <a href='https://app.suredone.com/settings/organization/accountID' target='_blank' rel='noopener noreferrer'>SureDone ID</a> and <b>save/publish</b> the changes.</p>
        <h1 style={{ width: '100%', margin: '30px 0 10px' }}><u>Please make sure that:</u></h1> <br />
        <Checks>
          <li>1. You have <b>not</b> added the widget to a <b>global zone</b> of your storefront.</li>
          <li>2. You have created a <b>results page</b> and added <b>this widget there too</b>.</li>
          <li>3. You have added <b>only 1 widget per page</b> (one on the home page and one on the results page).</li>
          <li>4. You have configured your <b>SureDone ID</b> correctly in <b>both widgets</b>.</li>
        </Checks>
      </Alert>
    )
  }

  if (paymentLoading) return <Spinner />

  if (paymentData && shouldCheckPayment(storefront, isDemoUser)) {
    const { checkStorefrontStatus } = paymentData || {}
    const { status } = checkStorefrontStatus || {}
    if (status !== 'ACTIVE') return <p>You need an active subscription to use the app.</p>
  }

  if (errorYear || errorMake || errorModel || paymentError) return <p>Error while fetching data. If you are seeing this error within your store editor, please check the live version.</p>

  return (
    <Section id='initial-search'>
      <WrapperDropdownColumn>
        <FitSelect
          autoFocus
          id='year'
          label='Year'
          loading={!dataYear}
          disabled={!dataYear}
          placeholder='Year'
          value={selected.year}
          onChange={handleChange}
          options={getOptions('year')}
        />
        <FitSelect
          id='make'
          label='Make'
          loading={!dataMake && selected?.year}
          value={selected.make}
          onChange={handleChange}
          disabled={!dataMake || !selected?.year}
          options={getOptions('make')}
          placeholder='Make'
        />
        <FitSelect
          id='model'
          label='Model'
          loading={!dataModel && selected?.make}
          value={selected.model}
          onChange={handleChange}
          disabled={!dataModel || !selected?.make}
          options={getOptions('model')}
          placeholder='Model'
        />
      </WrapperDropdownColumn>
      <WrapperButtonsColumn className='sd-myGarageButton-initialSearch'>
        <MyGarageButton />
      </WrapperButtonsColumn>
    </Section>
  )
}

const Section = styled.section`
  gap: 2%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: center;
  
  &#initial-search {
    input:focus {
      outline: none !important;
    }
  }
`
const WrapperDropdownColumn = styled.div`
  width: 79%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    padding: 4px 0;
    flex-wrap: wrap;
  }
`

const WrapperButtonsColumn = styled.div`
  width: 19%;
  display: flex;
  align-items: end;
  justify-content: space-around;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
  }
`

const Checks = styled.ul`
  font-size: 24px;
  margin-bottom: 10px;

  li {
    padding: 5px 0;
  }
`
