import React, { useState } from 'react'
import styled from 'styled-components'
import { Aside, FitResults } from '../components'
import { showOriginalViewQParam } from '../utils'
import { useCustomYMMSearch, useFitment, useQueryParams } from '../hooks'

export const SearchPage = () => {
  const nativeSearchPage = document.getElementById('SD-SearchPage') || document.querySelector('.SD-SearchPage')
  const nativeSearchNav = document.getElementById('SD-SearchNav') || document.querySelector('.SD-SearchNav')
  const { searchResultSKUs: nativeSearchResultsSKUs } = window?.sdFitmentData || {}
  const { year, make, model } = useCustomYMMSearch()
  const { results } = useFitment()
  const qparams = useQueryParams()
  const queryText = qparams.get('search_query') || qparams.get('q')
  const showOriginalView = window.location.search?.includes(showOriginalViewQParam) || !(year && make && model)

  if (showOriginalView) {
    if (nativeSearchPage && nativeSearchPage.style) nativeSearchPage.style.display = 'block'
    if (nativeSearchNav && nativeSearchPage.style) nativeSearchNav.style.display = 'block'
  }
  const [showFilters, setShowFilters] = useState(false)

  const getNativeSearchLink = () => {
    const url = new URL(window?.location.href)
    url?.searchParams.set(showOriginalViewQParam, true)
    return url?.href
  }

  const productsThatMatchByGUID = results?.raw?.filter(i => nativeSearchResultsSKUs?.includes(i?.guid?.toString())) || []
  const noResults = !productsThatMatchByGUID?.length

  if (showOriginalView) {
    return null
  }

  const ResultsLabel = () => {
    if (!showOriginalView) {
      if (noResults) {
        return (
          <>
            <h2 className='see-full-search' style={{ margin: '10px 0' }}>No results were found matching <b>{queryText}</b> and your last fitment search <b>{year} {make} {model}</b>.</h2><h3>Click <b><a href={getNativeSearchLink()}>here</a></b> to see full search result.</h3>
          </>
        )
      } else {
        return (
          <div>
            <h1>Results that fit <b>{queryText}</b> and <b>{year} {make} {model}</b></h1>
            <p className='see-full-search' style={{ margin: '10px 0' }}>Click <a href={getNativeSearchLink()}>here</a> to see full search result.</p>
          </div>
        )
      }
    }
    return null
  }

  return (
    <>
      <ResultsLabel />
      <Wrapper shopify className={`fitment-category-results ${showOriginalView && 'inactive'}`}>
        <Aside showFilters={showFilters} setShowFilters={setShowFilters} />
        <FitResults customList={productsThatMatchByGUID}>
          <Button
            className='show-filters-button btn button'
            onClick={() => setShowFilters(s => !s)}
          >
            Filter Results
          </Button>
        </FitResults>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  &.inactive {
    display: none;
  }
`

const Button = styled.button`
  @media (min-width: 577px) {
    display: none;
  }
`
