import useSWR from 'swr'
import axios from 'axios'

const fetcher = (...reqObjects) => {
  const f = reqObject => {
    const { type, url, body, headers } = reqObject
    if (type === 'REST') return axios.get(url).then(res => res.data)
    if (type === 'GQL') {
      return axios({
        url,
        headers,
        data: body,
        method: 'post'
      }).then(res => res.data)
    }
  }
  return Promise.all(reqObjects.map(reqObject => f(reqObject)))
}

export default function useMultipleRequests (reqObjects, swrOptions) {
  const multipleResults = useSWR(reqObjects, fetcher, swrOptions)
  const { data, error } = multipleResults
  return { data, error }
}
