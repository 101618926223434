import React from 'react'
import { useFitment } from '../hooks'
import { useCatTree } from '../hooks/useCatTree'
import { Spinner } from './Spinner'
import { FilterMenu, Item, Label } from '.'

export const FitCategoriesFilterGroupBigcommerce = ({ useWindowToNavigate }) => {
  const { results, loadingResults } = useFitment()
  const availableCategories = (results?.categories?.map(category => category._id)) || []
  const { catTrees, loading, error } = useCatTree(availableCategories || [])
  const flatTree = (flattenTree((catTrees || []).map(catTree => catTree?.data?.site))) || []
  const prunnedFlattenTree = pruneTree(flatTree, availableCategories)
  const isLoading = loadingResults || loading
  if (error) return <p>Error while fetching category name.</p>
  if (!isLoading && prunnedFlattenTree?.length <= 0) return

  return (
    <FilterMenu title='categories'>
      {isLoading
        ? <Spinner />
        : (
            prunnedFlattenTree.map((catTree, idx) => (
              <CategoryTreeRoot
                categoryTree={catTree}
                key={idx}
                useWindowToNavigate={useWindowToNavigate}
              />)
            ))}
    </FilterMenu>
  )
}

function mergeTrees (accum, current, childNodes) {
  // First lvl only 1 element in the array since is the queryable entityId
  const tree = current.categoryTree[0]
  if (!tree) return accum
  if (accum.length === 0) {
    accum = [{
      entityId: tree.entityId,
      name: tree.name,
      children: tree.children
    }]
    return accum
  } else {
    // Check if already a subtree
    const swapped = { wasSwapped: false }
    const newSwappedTree = generateNewSubTree(accum, tree, swapped, childNodes)
    if (swapped.wasSwapped) return newSwappedTree
    return [...accum, {
      entityId: tree.entityId,
      name: tree.name,
      children: tree.children
    }]
  }
}

// We know each element of the array is the root of their tree and have no brothers
export const flattenTree = (treeArray) => {
  const childNodes = {}
  treeArray.forEach(
    catTree => {
      const tree = catTree.categoryTree[0]
      if (!tree) return
      const childrenSet = createRootChildrenSet(tree.children)
      childNodes[tree.entityId] = childrenSet
    }
  )
  const result = treeArray.reduce((accum, current) => mergeTrees(accum, current, childNodes), [])
  return result
}

// Creates an object where each key is the root and value is all the children of the tree
const createRootChildrenSet = (trees) => {
  const result = new Set()
  function collectChildren (node) {
    if (node) {
      result.add(node.entityId) // Add the entityId of the current node

      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          collectChildren(child)
        }
      }
    }
  }

  // Iterate through all elements in the tree array
  for (const tree of trees) {
    collectChildren(tree)
  }

  return result
}

const generateNewSubTree = (accumTree, subtree, swapped, eachChildrenTable) => {
  if (!accumTree || accumTree.length === 0) return []
  // Check if subtree is a subtree of accumTree
  const indexOfBrother = accumTree.findIndex(brothersNodes => brothersNodes.entityId === subtree.entityId)
  if (indexOfBrother >= 0) {
    accumTree[indexOfBrother] = subtree
    swapped.wasSwapped = true
    return accumTree
  } else {
    // Check if some accumTree is subtree of subtree
    if (eachChildrenTable.hasOwnProperty(subtree.entityId)) {
      const indexOfAccumSubTree = accumTree.findIndex(brothersNodes => eachChildrenTable[subtree.entityId].has(brothersNodes.entityId))
      if (indexOfAccumSubTree >= 0) {
        // accumTree is subtree of subtree
        const oldAccumTree = accumTree[indexOfAccumSubTree]
        accumTree[indexOfAccumSubTree] = subtree
        swapped.wasSwapped = true
        return generateNewSubTree(accumTree, oldAccumTree, swapped, eachChildrenTable)
      }
    }
    return accumTree.map(brotherNode => ({
      ...brotherNode, children: generateNewSubTree(brotherNode.children, subtree, swapped, eachChildrenTable)
    }))
  }
}
export function pruneTree (tree, keepEntityIds) {
  function pruneRecursive (node) {
    if (!node.children || node.children.length === 0) {
      // Leaf node
      if (!keepEntityIds.includes(node.entityId)) {
        return null
      }
    } else {
      // Non-leaf node
      node.children = node.children
        .map(child => pruneRecursive(child))
        .filter(child => child !== null)

      // If all children are pruned, prune the current node as well
      if (node.children.length === 0 && !keepEntityIds.includes(node.entityId)) {
        return null
      }
    }

    return node
  }

  const prunedTree = tree
    .map(node => pruneRecursive(node))
    .filter(node => node !== null)

  return prunedTree
}

const CategoryTreeRoot = ({ categoryTree, useWindowToNavigate, treeLvl = 0 }) => {
  if (Object.keys(categoryTree).length > 0) {
    const { name, entityId, children: treeChildren } = categoryTree
    return (
      <CategoryNode
        useWindowToNavigate={useWindowToNavigate}
        category={entityId}
        categoryName={name}
        key={entityId}
        treeLvl={treeLvl}
      >
        {treeChildren.length > 0 && (
          treeChildren.map((subTree, idx) =>
            <CategoryTreeRoot
              categoryTree={subTree}
              useWindowToNavigate={useWindowToNavigate}
              treeLvl={treeLvl + 1}
              key={idx}
            />
          ))}
      </CategoryNode>
    )
  } else {
    return null
  }
}

const CategoryNode = ({ category, categoryName, treeLvl, useWindowToNavigate, children }) => {
  const { queryParams, addFilter, removeFilter } = useFitment({ useWindowToNavigate })
  const categories = queryParams.get('categories')
  const isChecked = val => {
    const parsed = categories?.split('*')?.map(i => i.replaceAll(',', ' '))
    if (!val) return !!parsed?.includes('Other')
    return !!parsed?.includes(val)
  }

  const handleCheck = (e, val) => {
    const wasChecked = e.target.checked
    if (wasChecked) { return addFilter('categories', val) }
    return removeFilter('categories', val)
  }

  return (
    <>
      <Item className={`menu__item cat-${categoryName?.trim()?.toLowerCase()?.replaceAll(' ', '-')} level-${treeLvl}`}>
        <Label check className='menu__value'>
          <input
            type='checkbox'
            checked={isChecked(`${category}`)}
            onChange={e => handleCheck(e, `${category}`)}
          />
          <span>
            {categoryName}
          </span>
        </Label>
      </Item>
      {children && <div style={{ paddingLeft: '15px' }}>
        {children}
      </div>}
    </>
  )
}
