import React from 'react'
import styled from 'styled-components'
import { useFitment } from '../hooks'
import { FitPagination, ProductCard, Spinner } from '.'

export const FitResults = ({ children, customList }) => {
  const { loadingResults, errorResults, results } = useFitment()
  const { raw: products, totalFacetedProducts: resultsCount } = results || {}
  const amountOfResults = resultsCount?.[0]?.total

  if (errorResults) return <p>{errorResults.message}</p>

  return loadingResults
    ? <Spinner />
    : (
      <Wrapper>
        {children}
        {customList && (customList?.length ? customList.map(i => <ProductCard key={i._id} item={i} />) : 'No results')}
        {!customList && amountOfResults > 0 && products.map(i => <ProductCard key={i._id} item={i} />)}
        <FitPagination />
      </Wrapper>
      )
}

const Wrapper = styled.section`
  gap: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
