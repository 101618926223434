import React from 'react'
import { Spinner } from './Spinner'
import styled from 'styled-components'
import { useShopData } from '../hooks'
import { FitsTable } from './FitsTable'
import { useQuery } from '@apollo/client'
import { GET_FITS_BY_ID } from '../queries/Fitment'
import { sortGroups, sortHeaders, useLabel, entireColumnIsEmpty, shouldHideColumn, convertYMMKeysToLowerCase, completeDataForMissingHeaders } from '../utils'

const columnModifiers = (header, rawData) => {
  const emptyColumnClass = entireColumnIsEmpty(header, rawData) ? 'entire-col-empty' : ''
  const hiddenColumnClass = shouldHideColumn(header) ? 'hidden-col' : ''
  return `${emptyColumnClass} ${hiddenColumnClass}`
}

const Table = ({ data, rawData }) => {
  const { groups, headers } = data || {}

  return (
    <>
      <p className='fits-header'>This part fits the following vehicles:</p>
      <TableWrapper className='fits-table'>
        <thead>
          <tr>
            <th className='initial'>Model</th>
            {headers?.map((i, idx) => (
              <th className={`t-value-${i} t-col-${i} ${columnModifiers(i, rawData)}`} key={idx}>
                {useLabel(i)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groups?.map((car, idx) => {
            return Object.entries(car)?.map(([key, values], i) => {
              const formattedValues = completeDataForMissingHeaders(headers, values)
              return (
                <React.Fragment key={i}>
                  <tr key={idx} className={`t-row ${key}`}>
                    <th rowSpan={formattedValues?.length} className={`t-value-${key || 'empty'}`}>{key?.replaceAll('*', ' ')}</th>
                    {Object.keys(formattedValues[0])?.map((field, j) => (
                      <td key={j} className={`t-value-${formattedValues[0][field] || 'empty'} t-col-${headers[j]} ${columnModifiers(headers[j], rawData)}`}>{formattedValues[0][headers[j]] || ''}</td>
                    ))}
                  </tr>
                  {formattedValues?.slice(1)?.map((value, k) => (
                    <tr key={k}>
                      {Object.keys(value)?.map((field, l) => (
                        <td key={l} className={`t-value-${value[field] || 'empty'} t-col-${headers[l]} ${columnModifiers(headers[l], rawData)}`}>{value[headers[l]] || ''}</td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              )
            })
          })}
        </tbody>
      </TableWrapper>
    </>
  )
}

export const FitsTableAdvanced = () => {
  const shopData = useShopData()
  const { suredoneID, storefront } = shopData || {}
  const sku = window?.sdFitmentData?.sku
  const { data, loading, error } = useQuery(GET_FITS_BY_ID, {
    variables: {
      sku,
      storefront,
      userId: Number(suredoneID),
      domain: window?.location?.origin
    }
  })
  const { getFitsByID: fits } = data || {}

  if (!sku) return null
  if (error) return <FitsTable /> // Uses the "basic" fits table

  if (fits?.length) {
    // Exposes the lastYMMSearch in window.sdFitmentData.fitsById
    if (window.sdFitmentData) {
      window.sdFitmentData.fitsById = fits
    } else {
      window.sdFitmentData = {}
      window.sdFitmentData.fitsById = fits
    }
  }

  const allHeaders = new Set()
  const groupedData = {}
  fits?.forEach(item => { // Iterates the data and groups it by 'year*make*model'
    const { year, make, model, ...rest } = convertYMMKeysToLowerCase(item) || {}
    const key = `${year}*${make}*${model}`
    if (!groupedData[key]) {
      groupedData[key] = []
    }
    const alreadyExists = groupedData[key].some(i => {
      for (const prop in rest) {
        if (rest[prop] !== i[prop]) {
          return false
        }
      }
      return true
    })
    if (!alreadyExists) {
      groupedData[key].push(rest)
    }
    Object.keys(rest).forEach(header => allHeaders.add(header))
  })

  const headers = Array.from(allHeaders)
  const sortedHeaders = sortHeaders(headers)
  const groups = Object.entries(groupedData)?.map(([key, value]) => ({ [key]: value }))
  const sortedGroups = sortGroups(groups)

  return (
    loading
      ? <Spinner />
      : <Table data={{ groups: sortedGroups, headers: sortedHeaders }} rawData={fits} />
  )
}

const TableWrapper = styled.table`
  border: none;
  display: block;
  overflow: auto;
  max-width: 100%;
  text-align: left;
  border-collapse: collapse;
  
  td, th {
    padding: 5px;
    border: 1px solid;
    line-height: normal;
  }
  
  td {
    padding: 5
  }

  thead > tr, th {
    background-color: #c2c2c2
  }

  .hidden-col,
  .entire-col-empty {
    display: none;
  }
`
