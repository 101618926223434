import React, { Children, useState } from 'react'
import styled from 'styled-components'

const viewMoreThreshold = 5

const FilterMenu = ({ title, children, results }) => {
  const childrenArray = children && Children.toArray(children)
  const childrenAmount = childrenArray ? childrenArray.length : 0
  const onlyOther = results?.[title]?.length === 1 && (results?.[title]?.[0]?._id === '' || results?.[title]?.[0]?._id === null) // The only filter for the group is "Other"
  const [viewMore, setShowViewMore] = useState(() => childrenAmount > viewMoreThreshold)
  const [sliceEnd, setSliceEnd] = useState(viewMoreThreshold)
  const handleViewMore = () => {
    setSliceEnd(childrenAmount.length)
    setShowViewMore(!viewMore)
  }

  return (
    childrenAmount === 0 || onlyOther
      ? null
      : (
        <>
          <Menu className={`menu ${title}`}>
            <Item className='menu__item'>
              <Title className='menu__title'>{title}</Title>
              <Menu className='menu__group'>
                {(childrenArray.slice(0, sliceEnd)).map(child => child)}
                {viewMore &&
                  <Button onClick={handleViewMore}>
                    View more
                  </Button>}
              </Menu>
            </Item>
          </Menu>
          <Hr className={`after-${title}`} />
        </>
        ))
}

const Menu = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0 !important;
`

const Item = styled.li`
  width: 100%;
  padding: 0 5px;
  list-style: none;
`

const Title = styled.span`
  display: block;
  font-weight: bold;

  &::first-letter {
    text-transform: uppercase;
  }
`

const Label = styled.label`
  display: flex;
  opacity: ${p => p.disabled ? 0.6 : 1};
  ${p => p.disabled ? 'pointer-events: none;' : 'cursor: pointer;'}

  input {
    margin-right: 5px;
  }
`

const Hr = styled.hr`
  margin: 10px 0;
`
const Button = styled.button`
  border: none;
  cursor: pointer;
  background-color: transparent;
`

export { FilterMenu, Hr, Item, Label }
