import React from 'react'
import { Item, Label } from '.'

export const FilterItem = ({ className, filterValue, isChecked, handleCheck }) => {
  const valueToShow = !filterValue && filterValue !== 0 ? 'Other' : filterValue

  return (
    <Item className={`${className} sd-filter-${valueToShow?.replaceAll(' ', '-')}`} key={filterValue}>
      <Label check className='menu__value'>
        <input
          type='checkbox'
          checked={isChecked(filterValue)}
          onChange={e => handleCheck(e, filterValue)}
        />
        <span>{valueToShow}</span>
      </Label>
    </Item>
  )
}
