import React from 'react'
import { useFitment, useShopData } from '../hooks'
import { FitPriceRange, FilterMenu, Label, FilterItem } from '.'

export const FilterGroup = ({ id, useWindowToNavigate }) => {
  const { storefront: channel, instance } = useShopData() || {}
  const { results, queryParams, addFilter, removeFilter } = useFitment({ useWindowToNavigate })
  delete results.__typename
  const options = results?.[id]?.map(({ _id, count }) => ({ _id, count }))?.filter(i => i !== 'totalFacetedProducts')
  const allOptionsAreNull = options?.every(i => i._id === null)

  const isChecked = val => {
    const parsed = queryParams && queryParams.get(id)?.split('*')?.map(i => i.replaceAll(',', ' '))
    if (!val) return !!parsed?.includes('Other')
    return !!parsed?.includes(val)
  }

  const handleCheck = (e, val) => {
    let formatted
    if (id === 'price') formatted = `${val?.min}-${val?.max}`
    if (!val) formatted = 'Other'
    const wasChecked = e.target.checked
    if (wasChecked) { return addFilter(id, formatted || val) }
    return removeFilter(id, formatted || val)
  }

  // Adds the sd-not-relevant class if this filter leads to a page with no results
  // If the store uses an instance greater than 0, you must include it in window.shopData = {instance: 2, ...}
  const leadsToNoResults = (item) => {
    const rawResults = results?.raw || []
    const existResultsForThisFilter = rawResults.some(result => {
      const existsForSimpleKey = result?.facets?.[id]?.includes(item) // e.g. color
      const existsForComplexKey = result?.facets?.[`${channel}${instance || ''}${id}`.toLowerCase().replace(/\s/g, '')]?.includes(item) // e.g. bigcommerce2boxwidth
      return existsForSimpleKey || existsForComplexKey
    })

    return existResultsForThisFilter ? '' : 'sd-not-relevant'
  }

  const prepareItem = (filterValue, parent, idx) => {
    // Slider Price Range
    if (parent === 'price') {
      return <FitPriceRange key={filterValue} filterValue={filterValue} useWindowToNavigate={useWindowToNavigate} />
    }

    // For Custom Multi Facets where the filter value is an array
    if (Array.isArray(filterValue)) {
      return filterValue?.map(fValue => (
        <FilterItem
          key={idx}
          filterValue={fValue}
          isChecked={isChecked}
          handleCheck={handleCheck}
          className={`menu__item ${leadsToNoResults(filterValue)}`}
        />
      ))
    }

    return (
      <FilterItem
        key={idx}
        filterValue={filterValue}
        isChecked={isChecked}
        handleCheck={handleCheck}
        className={`menu__item ${leadsToNoResults(filterValue)}`}
      />
    )
  }

  return (options?.length > 0 && !allOptionsAreNull && (
    <FilterMenu title={id} results={results}>
      {options.map((item, idx) => prepareItem(item?._id, id, idx))}
    </FilterMenu>
  ))
}

export const FixedFilterGroup = ({ title, value }) => {
  return (
    <FilterMenu title={title}>
      <Label className='menu__value' check disabled>
        <input defaultChecked type='checkbox' />
        <span>{value}</span>
      </Label>
    </FilterMenu>
  )
}
